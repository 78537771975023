import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
    <Navbar />
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
      <div className="bg-white rounded border shadow mt-10 p-6">
        <h1 className="text-2xl text-gray-800 font-semibold py-1">12 Car Insurance Discounts</h1>
        <p className="text-gray-700 text-sm">Fluctuating gas prices, maintenance costs and state fees; these are just a few of the factors that can drive up the
          costs of owning a car. While securing discounts in these areas may be nearly impossible, a little bit of research may
          allow you to save hundreds of dollars a year on your car insurance. Keep reading to discover some lesser-known
          discounts that may be available to you.</p>
        <ol className="list-disc list-inside">
          <li className="text-gray-700 text-sm">Multiple Policies. Does your insurance company offer other types of insurance? Talk to a representative about
            bundling your car insurance with other policies, such as home insurance or renter’s insurance.
          </li>
          <li className="text-gray-700 text-sm">Multi-vehicle. In addition to rewarding customers with multiple policies, your insurance company may offer an
            incentive for insuring multiple vehicles through them.
          </li>
          <li className="text-gray-700 text-sm">“Green” Vehicle. If you drive an alternative-fuel or hybrid vehicle, you may be eligible for this discount.</li>
          <li className="text-gray-700 text-sm">Low Mileage. While it is estimated that the average driver puts around 12,000 miles per year on their vehicle, you
            may drive significantly less. Most insurance companies offer a low mileage discount, though you may be required to
            provide monthly proof of your driving habits through electronic monitoring.
          </li>
          <li className="text-gray-700 text-sm">Military. Policy holders who are active members of the armed forces, as well as their family members, may qualify
            for a military discount.
          </li>
          <li className="text-gray-700 text-sm">Occupational. Members of some unions, or professionals working for specified companies or in certain industries,
            may qualify for an occupational auto insurance discount.
          </li>
          <li className="text-gray-700 text-sm">Good Student. Many car insurance companies offer an incentive for high school and college students who maintain a
            B average or higher in their studies.
          </li>
          <li className="text-gray-700 text-sm">Distant Student. Does your policy cover a young student who attends school far from home? If so, you may be
            eligible for the distant student discount.
          </li>
          <li className="text-gray-700 text-sm">Safe Driver. Safe drivers are less likely to be in an accident, and are less likely to require a payout from their
            insurance company. To provide an incentive for safe driving habits, many car insurance companies pass on their own
            savings by offering a safe driver discount to policy holders who have a clean driving record.
          </li>
          <li className="text-gray-700 text-sm">Paperless Billing. In addition to being environmentally responsible, paperless billing saves money for your auto
            insurance company. Once again, some companies may pass on these savings by offering a discount to their policy
            holders.
          </li>
          <li className="text-gray-700 text-sm">Full Payment. Almost all car insurance companies offer a discount for paying your entire premium upfront. Consider
            taking advantage of this discount, and enjoy significant savings on your car insurance.
          </li>
          <li className="text-gray-700 text-sm">Defensive Driver. If you have taken a defensive driving course, you may be eligible for the defensive driver
            discount. Note that some insurance companies only offer this discount to senior drivers, so be sure to talk to a
            representative before enrolling in a defensive driving class.
          </li>
        </ol>
        <p className="text-gray-700 text-sm">While many expenses of owning a car offer little room for negotiation, knowing the right questions to ask may result
          in significant savings on car insurance.</p>
      </div>
    </div>
    <Footer/>
  </>
)